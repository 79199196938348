@import '../style/include.scss';

$maxWidth:600px;
.sign-in-card{
    border:none !important;
    max-width:$maxWidth;
    width:100%;
    margin:0 auto;
    border-radius: 0 !important;
    .card-img-top{
        border-radius: $border-radius;
        margin-top:2rem;
    }
}
@media only screen and (max-width: $maxWidth) {
    .sign-in-card{
        .card-img-top{
            border-radius: 0 !important;
            margin-top:0;
        }
    }

}