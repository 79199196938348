@import "./include.scss";

.btn-min{
    border:none;
    background: none;
}
.fill-with-link{
    display:flex;
    >*{
        flex:1;
    }
}
.well{
    background-color:$gray-200;
    border-radius: $border-radius;
}