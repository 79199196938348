@import './vars.scss';
@import './_variables.scss';

%abs-fill{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

%v-scroll{
    overflow-y:auto;
    overflow-y:overlay;
    overflow-x:hidden;
    -webkit-overflow-scrolling: touch;

}