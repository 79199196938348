@import './include.scss';
@import "~bootstrap/scss/bootstrap.scss";
@import './_bootswatch.scss';
@import './common.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#fff;
}

html, body, #root{
  position: relative;
  width:100%;
  height:100%;
  margin:0;
  padding:0;
  overflow: hidden;
}

#root{
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.lock-screen-overlay-default{
  background-color: rgba($color: #fff, $alpha: 0.01) !important;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  .lock-screen-overlay-body{
    background-color: rgba($color: #fff, $alpha: 0.01) !important;
    padding:0;
    margin:5rem 1rem;
    h1{
      color:$gray-400 !important;;
    }
  }
}