@import '../style/include.scss';

.main-tabs{
    list-style: none;
    display:flex;
    flex-direction: row;
    margin:0;
    padding:0;

    transition: transform $vQuickTrans;

    &.hidden{
        transform: translateY(110px);
        position: absolute;
        bottom:0;
        width:100%;
    }

    >li{
        flex:1;
        display:flex;
        flex-direction: column;
        align-items: center;
        margin:0;
        padding:1rem 0;
        background-color:$primary;
        transition: background-color $vQuickTrans, color $vQuickTrans;
        &.active,&:hover{
            background-color: rgba($color: $primary, $alpha: 0.9);
            color:$white;
        }
        span{
            font-size: 0.6rem;
            margin-top:0.2rem;
        }
        svg{
            width:1.5rem;
            height:1.5rem;
        }
    }
}