$vDarkBgForeground:#fff;
$vDarkBg:#222;

$vQuickTransTime: 0.3s !default;

$vQuickTrans: $vQuickTransTime ease-in-out !default;
$vQuickTransEnd: $vQuickTrans step-end !default;

$vModalBgColor:#fff;
$vModalWidth:600px;
$vBorderRadius:0px;