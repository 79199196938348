@import './transitions.scss';

.lock-screen-overlay-default{

    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10100;

    visibility: hidden;
    opacity: 0;
    transition: opacity $vQuickTrans, visibility $vQuickTrans;
    

    &.lock-screen-overlay-light{
        background-color: rgba($color: #fff, $alpha: 0.3);
    }

    &.lock-screen-overlay-dark{
        background-color: rgba($color: #000, $alpha: 0.3);
    }

    &.lock-screen-overlay-open{
        opacity: 1;
        visibility: visible;
    }

    .lock-screen-overlay-body{
        background-color:#fff;
        padding:2rem;
        margin:2rem;
        border-radius: 8px;

        h1{
            font-size: 1rem;
            text-transform: none;
            margin:0;
            padding:0;
        }
    }
}