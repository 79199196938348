
@import '../../style/include.scss';

.task-runner{

    display:flex;
    flex-direction: column;
    flex:1;

    .task-runner-main{
        flex:1;
        @extend %v-scroll;
    }

    .task-runner-bottom{
        display:flex;
        flex-direction: row;
        align-items: center;
        >*{
            flex:1;
            text-align: center;
        }

        .btn{
            font-size: 2.5rem;
            padding:1.5rem 0.5rem 0.6rem 0.5rem;
            transform:translateY(100px);
            transition: transform 0.3s ease-in-out;
            &.active{
                transform:translateY(0);
            }
        }

        $rad:5rem;
        $push:2rem;
        .text-left .btn{
            border-radius: 0 $rad 0 0;
            padding-right:$push;
        }

        .text-right .btn{
            border-radius: $rad 0 0 0;
            padding-left:$push;
        }
    }

    .task-runner-question-frame{
        position: relative;
        flex:1;
        display: flex;
        flex-direction: column;
    }

    .task-runner-header{
        text-transform: none;
        margin:1rem 0 3rem 0;
    }

    .task-runner-body{
        padding:1rem;
        display:flex;
        flex-direction: column;
        .task-runner-content{
            flex:1;
            position: relative;
        }
    }

}