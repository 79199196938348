.scroll-view-vertical-flex{
    flex:1;
    width:100%;
    height:100%;
    overflow-x:hidden;
    overflow-y: auto;
    overflow-y: overlay;
    -webkit-overflow-scrolling: touch;
}
.scroll-view-horizontal-flex{
    flex:1;
    width:100%;
    height:100%;
    overflow-y:hidden;
    overflow-x: auto;
    overflow-x: overlay;
    -webkit-overflow-scrolling: touch;
}
.scroll-view-vertical-absolute{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow-x:hidden;
    overflow-y: auto;
    overflow-y: overlay;
    -webkit-overflow-scrolling: touch;
}
.scroll-view-horizontal-absolute{
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    overflow-y:hidden;
    overflow-x: auto;
    overflow-x: overlay;
    -webkit-overflow-scrolling: touch;
}