@import '../style/include.scss';

.editor-layout{
    flex:1;
    display: flex;
    flex-direction: row;

    .editor-layout-menu{
        list-style-type: none;
        margin:0;
        padding:0;
        >li{
            padding:1rem;
            cursor: pointer;
        }
    }

    .editor-layout-content{
        flex:1;
        overflow: hidden;
        position: relative;
        >div{
            @extend %abs-fill;
            transition: top $vQuickTrans;

            .editor-layout-content-item{
                @extend %abs-fill;
                display: flex;
                flex-direction: column;

                .editor-layout-container{
                    display: flex;
                    flex-direction: column;
                    padding:0 1rem;
                    width:100%;
                    height:100%;
                    @extend %v-scroll;
                }
            }
        }
    }

}