@import '../style/include.scss';

body{
    font-size: 12px;
}

.main-layout{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    flex:1;
    .main-nav{
        display:flex;
        flex-direction: row;
        background:$vDarkBg;
        padding:10px;
        justify-content: space-between;
        color:$vDarkBgForeground;
        a{
            color:$vDarkBgForeground;
        }
        svg{
            fill:$vDarkBgForeground;
        }
        ul{
            list-style: none;
            margin:0;
            padding:0;
        }
    }

    .main-layout-content{
        flex:1;
        position: relative;
    }

    .main-layout-lock{
        @extend %abs-fill;
        background: rgba($color: #fff, $alpha: 0.01);
        z-index: 1000;
        opacity: 0;
        visibility: hidden;

        transition: opacity $vQuickTrans, visibility $vQuickTransTime;

        &.locked{
            opacity: 1;
            visibility: visible;
        }
    }
}