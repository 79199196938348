@import '../style/include.scss';

.page{
    @extend %abs-fill;
    &.scrollable{
        @extend %v-scroll;
    }
    display: flex;
    flex-direction: column;
    .nav-route-in &,
    .nav-route-out & {
        overflow: hidden;
        overflow-y: hidden;
    }
    .page-content{
        display: flex;
        flex:1;
        flex-direction: column;
        &.container{
            padding-top:3rem;
        }
    }

    
}
.bp-stack .page.no-stack-padding .page-content.container{
    padding-top:0rem;
    padding-left:0;
    padding-right:0;
}