@import "../style/include.scss";

.imp-modal {
    @extend %abs-fill;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0.1;

    &.modal-wide{
        background-color: $vModalBgColor;
        .imp-modal-body{
            box-shadow: none;
            padding-left:0;
            padding-right:0;
            margin-top:0 !important;
        }
    }

    overflow: auto;
    overflow-y: overlay;
    z-index: 10000;

    > .container {
        z-index: 1;
        position: relative;
    }
    &.narrow {
        > .container {
            max-width: $vModalWidth;
        }
    }
    &.no-h-padding .imp-modal-body {
        padding-left: 0;
        padding-right: 0;
        .btn-close{
            margin-right:20px;
        }
    }

    .imp-modal-body {
        background-color: $vModalBgColor;
        margin: 100px auto 50px;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
        padding: 30px 50px 50px 50px;
        border-radius: $vBorderRadius;
        .bp-mobile &{
            margin: 100px 25px 50px 25px;
            width:auto;
        }
        transition: transform $vQuickTransTime;

        .imp-modal-content{
            position: relative;
        }
    }

    transition: opacity $vQuickTrans, visibility $vQuickTransTime;
    &.imp-modal-closed {
        opacity: 0;
        visibility: hidden;
        .imp-modal-body{
            transform:translateY(-400px);
        }
    }
    &.imp-modal-open {
        opacity: 1;
        visibility: visible;
    }
}

.btn-close {
    padding: 0 0 5px 0;
}
